import Vue from 'vue';

import Page from './page/main.vue';

import { directoryCurrenciesStore } from "@/entries/directory-currencies/store";
import {i18n} from '/public/i18n';

new Vue({
    el: "#app",
    store: directoryCurrenciesStore,
    render: h => h(Page),
    i18n,
});
