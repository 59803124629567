<template>
  <div class="directory-currencies-wrapper">
    <directory-header />
    <div class="dashboard">
      <template>
        <div class="page-title">
          {{ $t('directory-currencies.page-title') }}
        </div>
        <div class="filters-web">
          <currencies-select
            v-model="filter.currencies"
            :list="currenciesWithNetworks"
          />
          <networks-select
            v-model="filter.networks"
            :list="networks"
          />
          <fiats-select
            v-model="filter.fiat"
            :list="fiatCoins"
          />
          <refresh-btn @click="getCurrencies" />
        </div>
        <div class="filters-mobile">

          <refresh-btn @click="getCurrencies" />
          <filter-mobile
            @confirm="confirmFilters"
            @reset="preFilterToDefault"
          >
            <div class="mobile-filters-wrapper">
              <currencies-select
                v-model="preFilter.currencies"
                :list="currenciesWithNetworks"
                class="filter-item"
              />
              <networks-select
                v-model="preFilter.networks"
                :list="networks"
                class="filter-item"
              />
              <fiats-select
                v-model="preFilter.fiat"
                :list="fiatCoins"
                class="filter-item"
              />
            </div>
          </filter-mobile>
        </div>
        <div v-if="loading" class="spinner-block">
          <spinner />
        </div>
        <currencies-table
          v-else
          :loading="loading"
          :list="filteredAndSortedList"
          @sort="sorting = $event"
        />
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { prettyFloor } from "@/entries/directory-currencies/filters"
import { cloneDeep } from "lodash"

export default {
  name: "Main",
  components: {
    DirectoryHeader: () => import(/* webpackChunkName: "entries/invoice/components/Header" */ '@/entries/directory-currencies/components/Header.vue'),
    CurrenciesTable: () => import(/* webpackChunkName: "entries/directory-currencies/components/Table" */ '@/entries/directory-currencies/components/Table'),
    CurrenciesSelect: () => import(/* webpackChunkName: "entries/directory-currencies/components/Select/currencies" */ '@/entries/directory-currencies/components/Select/currencies'),
    NetworksSelect: () => import(/* webpackChunkName: "entries/directory-currencies/components/Select/networks" */ '@/entries/directory-currencies/components/Select/networks'),
    FiatsSelect: () => import(/* webpackChunkName: "entries/directory-currencies/components/Select/fiats" */ '@/entries/directory-currencies/components/Select/fiats'),
    RefreshBtn: () => import(/* webpackChunkName: "entries/directory-currencies/components/Select/fiats" */ '@/entries/directory-currencies/components/RefreshBtn'),
    Spinner: () => import (/* webpackChunkName: "Spinner" */ "@/public/components/common/uiKit/spinner"),
    FilterMobile: () => import (/* webpackChunkName: "entries/directory-currencies/components/FilterToggler" */ "@/entries/directory-currencies/components/FilterToggler")
  },
  data() {
    return {
      sorting: {
        col: 'currency',
        direction: 'asc'
      },
      filterDefault: {
        currencies: [],
        networks: [],
        fiat: {
          id: 'USD',
          label: 'USD',
          alias: 'US Dollar'
        }
      },
      filter: {},
      preFilter: {}, //фильтр мобильной версии, после нажатия на кнопку confirm приравнивается к filter
      currenciesWithNetworks: [],
      currencies: [],
      networks: [],
      fiatCoins: [],
      loading: false
    };
  },
  watch: {
    filter: {
      handler(v) {
        this.preFilter = cloneDeep(v)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      state: state => state,
      lang: state => state.lang,
      darkTheme: state => state.theme === 'dark',
    }),
    sortDirection({sorting}) {
      return sorting.direction
    },
    filteredAndSortedList({ currencies, filter, sorting, sortDirection}) {
      const { currencies: currFilter, networks: netFilter, fiat: fiatFilter } = filter

      let filtered = currencies.map(curr => {
        return {
          ...curr,
          price: `${this.prettyFloor(curr.prices[fiatFilter.id], 7)} ${fiatFilter.label}`
        }
      })

      if (currFilter.length) {
        filtered = filtered.filter(curr => {
          return currFilter.some(({ id }) => curr['id'].includes(id))
        })
      }

      if (netFilter.length) {
        filtered = filtered.filter(({ network }) => {
          return netFilter.map(({ id }) => id).includes(network.id)
        })
      }

      let filteredAndSorting = filtered.sort((pre, next) => {
        const isAscDirection = sortDirection === 'asc'
        const { col: sortCol } = sorting

        if (sortCol === 'currency') {
          return isAscDirection ? pre.alias.localeCompare(next.alias) : next.alias.localeCompare(pre.alias)
        }
        switch (sortCol) {
          case 'currency':
            return isAscDirection ? pre[sortCol].alias.localeCompare(next[sortCol].alias) : next[sortCol].alias.localeCompare(pre[sortCol].alias)
          case 'network':
            return isAscDirection ? pre[sortCol].alias.localeCompare(next[sortCol].alias) : next[sortCol].alias.localeCompare(pre[sortCol].alias)
          case 'networkFee':
            return isAscDirection ? (pre['network'].networkFee - next['network'].networkFee) : (next['network'].networkFee - pre['network'].networkFee)
          case 'type':
            return isAscDirection ? pre.type.localeCompare(next.type) : next.type.localeCompare(pre.type)
        }
      })

      return filteredAndSorting
    }
  },
  created() {
    this.setDefaultTheme()
    this.getCurrencies()

    this.filter = cloneDeep(this.filterDefault)
  },
  methods: {
    prettyFloor,
    preFilterToDefault() {
      this.preFilter = cloneDeep(this.filterDefault)
    },
    confirmFilters() {
      this.filter = cloneDeep(this.preFilter)
    },
    setDefaultTheme() {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const urlTheme = urlParams.get('theme')
      const urlIsDarkTheme = urlTheme === 'dark'
      const productName = import.meta.env.VITE_PRODUCT_NAME
      const dark = productName !== 'Apollopayment' ? 'public-dark' : 'apollo-public-dark'
      const light = productName !== 'Apollopayment' ? 'public-light' : 'apollo-public-light'

      if (!!urlTheme) {
        this.$store.commit('setTheme', urlTheme);

        if (urlIsDarkTheme) document.documentElement.setAttribute("data-theme", dark);
        else document.documentElement.setAttribute("data-theme", light);

      } else {

        const themeOsDark = window.matchMedia("(prefers-color-scheme: dark)");
        const theme = localStorage.getItem('data-theme');

        if (theme === dark) {
          this.$store.commit('setTheme', 'dark');
          document.documentElement.setAttribute("data-theme", dark);
          localStorage.setItem("data-theme", dark);
        } else if (theme === light) {
          this.$store.commit('setTheme', 'light');
          document.documentElement.setAttribute("data-theme", light);
          localStorage.setItem("data-theme", light);
        } else if (themeOsDark?.matches) {
          this.$store.commit('setTheme', 'dark');
          document.documentElement.setAttribute("data-theme", dark);
          localStorage.setItem("data-theme", dark);
        } else {
          this.$store.commit('setTheme', 'light');
          document.documentElement.setAttribute("data-theme", light);
          localStorage.setItem("data-theme", light);
        }

        const urlParams = new URLSearchParams(queryString);
        urlParams.set('theme', this.darkTheme  ? 'dark' : 'light')

        window.history.replaceState( {
          theme: this.darkTheme  ? 'dark' : 'light'
        }, import.meta.env.VITE_PRODUCT_NAME, `${window.location.origin}${window.location.pathname}?${urlParams.toString()}` );
      }
    },
    async getCurrencies() {
      this.loading = true

      const res = await axios.get('public-api/reference');
      if (res?.data && res.data?.success) {

        this.currenciesWithNetworks = res.data.result.crypto || []
        this.fiatCoins = res.data.result.fiat.map(coin => ({ ...coin, 'id': coin.currency, 'label': coin.currency }))

        this.currenciesWithNetworks.forEach(curr => {
          const {id, alias, currency, prices} = curr

          if (curr.networks) {
            curr.networks.forEach(nw => {
              //заполняем массив сетей
              const networkInList = this.networks.some(({alias}) => alias === nw.alias)
              if (!networkInList) {
                this.networks.push(nw)
              }

              const isNative = !nw.isToken

              this.currencies.push({
                id: `${id}-${nw.id}`,
                alias,
                currency,
                network: nw,
                prices,
                type: isNative ? 'Native' : 'Token',
                source: isNative ? 'Withdrawal amount' : 'Advance balance'
              })
            })
          }
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  .directory-currencies-page {
    font-family: 'Gilroy';
    background: var(--body-color);

    .spinner-block {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 250px;
    }

    .directory-currencies-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;

      .dashboard {
        width: 80%;
        background-color: var(--bg-background-secondary);
        border-radius: 30px;
        padding: 30px;

        .page-title {
          color: var(--text-text-primary);
          font-size: $font-size-extra;
          font-weight: 500;

          margin-bottom: 30px;

          @include below_mobile {
            font-size: $font-size-big;
            margin-bottom: 20px;
          }
        }

        .filters-web {
          height: 48px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 48px;
          gap: 10px;
          margin-bottom: 20px;

          @include below_tablet {
            display: none;
          }
        }
        .filters-mobile {
          display: none;

          .mobile-filters-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            margin-bottom: 10px;

            .filter-item {
              min-width: 326px;
            }
          }

          @include below_tablet {
            height: 48px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            margin-bottom: 20px;
          }

          @include below_mobile {
            .base-filter-toggler {
              .base-filter-toggler__content {
                right: -71px;
              }
            }
          }
        }
      }
    }
  }
</style>
