import Vue from 'vue';
import BigNumber from "bignumber.js";

export const prettyFloor = (number, dp = 4) => {
  const splittingNumbers = number.split('.')
  if (Number(splittingNumbers[1])) {
    return new BigNumber(number).dp(dp).toString()
  } else {
    return splittingNumbers[0]
  }
}

export const getNetworkShortNameByNetworkName = (networkName) => {
  switch (networkName) {
    case 'tron':
      return 'TRC20'
    case "ethereum":
      return 'ERC20'
    case "bsc":
      return 'BEP20'
    case 'solana':
      return 'Solana'
    case 'bitcoin':
      return 'BTC'
    case 'fantom':
      return 'Fantom'
    case 'bitcoincash':
      return 'BCH'
    case 'litecoin':
      return 'LTC'
    case 'polygon':
      return 'Polygon'
    case 'ton':
      return 'TON'
    default:
      return '-'
  }
}

export const capitalized = (str) => {
  return str[0].toUpperCase() + str.slice(1)
}

Vue.filter('prettyFloor', prettyFloor);
Vue.filter('getNetworkShortNameByNetworkName', getNetworkShortNameByNetworkName);